<template>
  <v-container fluid class="no-padding">
    <v-divider
      :dark="$store.getters.getColorPalette().isDark"
      :color="$store.getters.getColorPalette().backgroundColorCode"
      class="divider"
    ></v-divider>
    <v-tabs
      :dark="$store.getters.getColorPalette().isDark"
      :background-color="$store.getters.getColorPalette().backgroundColorCode"
      v-model="tab"
      grow
    >
      <!-- <v-tabs-slider :color="$store.getters.getColorPalette().accentCode"></v-tabs-slider> -->
      <router-link
        v-if="
          this.$store.state.user &&
          this.$store.getters.getAccessByLevels(['logs'])
        "
        :style="{
          color: 'inherit',
          display: 'flex',
          'justify-content': 'space-between',
          'align-item': 'center',
          'text-decoration': 'none',
        }"
        :to="'/manufacturing/checklist'"
      >
        <v-tab>
          <v-icon
            :isDark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().accentCode"
            class="mr-2"
            small
            >mdi-note-check</v-icon
          >{{ $store.getters.getTextMap().check_list }}
        </v-tab>
      </router-link>
      <router-link
        v-if="
          this.$store.state.user &&
          this.$store.getters.getAccessByLevels(['logs'])
        "
        :style="{
          color: 'inherit',
          display: 'flex',
          'justify-content': 'space-between',
          'align-item': 'center',
          'text-decoration': 'none',
        }"
        :to="'/manufacturing/logbooks'"
      >
        <v-tab>
          <v-icon
            :isDark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().accentCode"
            class="mr-2"
            small
            >mdi-book-open-outline</v-icon
          >{{ $store.getters.getTextMap().log_book }}
        </v-tab>
      </router-link>
      <router-link
        :style="{
          color: 'inherit',
          display: 'flex',
          'justify-content': 'space-between',
          'align-item': 'center',
          'text-decoration': 'none',
        }"
        :to="'/manufacturing/store/requests'"
      >
        <v-tab>
          <v-icon
            :isDark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().accentCode"
            class="mr-2"
            small
            >mdi-view-dashboard-outline</v-icon
          >{{ $store.getters.getTextMap().store }}
        </v-tab>
      </router-link>
      <router-link
        :style="{
          color: 'inherit',
          display: 'flex',
          'justify-content': 'space-between',
          'align-item': 'center',
          'text-decoration': 'none',
        }"
        :to="'/manufacturing/batch'"
      >
        <v-tab>
          <v-icon
            :isDark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().accentCode"
            class="mr-2"
            small
            >mdi-view-dashboard-outline</v-icon
          >{{ $store.getters.getTextMap().batch }}
        </v-tab>
      </router-link>
    </v-tabs>
    <v-divider
      :dark="$store.getters.getColorPalette().isDark"
      :color="$store.getters.getColorPalette().backgroundColorCode"
      class="divider"
    ></v-divider>
    <!-- <v-row >
        <v-col cols="6">
          
          <router-link  :to="'/manufacturing/batch'">
            <v-card
            :style="{
      'border-radius': '5px',
      border:
        '1px solid' + $store.getters.getColorPalette().panelBorderColorCode,
        
    }"
    class="py-5"
    flat
    :dark="$store.getters.getColorPalette().isDark"
    :color="$store.getters.getColorPalette().background2ColorCode"
                
                >
                <h1>Batch <v-icon
                right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" large
                >mdi-chart-tree</v-icon></h1>
                
                </v-card>
           
          </router-link>
          
        </v-col>
        <v-col cols="6">
          <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/manufacturing/store'">
            <v-card
            :style="{
      'border-radius': '5px',
      border:
        '1px solid' + $store.getters.getColorPalette().panelBorderColorCode,
        
    }"
    class="py-5"
    flat
    :dark="$store.getters.getColorPalette().isDark"
    :color="$store.getters.getColorPalette().background2ColorCode"
                
                >
                <h1>Stores
                <v-icon
                right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" large
                >mdi-chart-tree</v-icon>
                </h1>
                </v-card>
          </router-link>
        </v-col>
      </v-row> -->
    <v-row no-gutters>
      <v-col>
        <router-view></router-view>
      </v-col>
    </v-row>
    <!-- <v-row >
                <v-col>
                    <v-card flat>
                      <v-sheet>
    <v-tabs
      :dark="$store.getters.getColorPalette().isDark"
      :background-color="$store.getters.getColorPalette().backgroundColorCode"
      show-arrows
      v-model="tab"
    >
      <v-tabs-slider :color="$store.getters.getColorPalette().accentCode"></v-tabs-slider>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/manufacturing/overview'">
      <v-tab>
        <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-view-dashboard-outline</v-icon>{{ $store.getters.getTextMap().overview }}
      </v-tab>
    </router-link>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/manufacturing/batches'">
      <v-tab>
        <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-abacus</v-icon>{{ $store.getters.getTextMap().batches }}
      </v-tab>
    </router-link>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/manufacturing/trackBatch'">
      <v-tab>
        <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-map-marker-path</v-icon>{{ $store.getters.getTextMap().track_batch }}
      </v-tab>
    </router-link>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/manufacturing/batchMovement'">
      <v-tab>
        <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-arrow-collapse-right</v-icon>{{ $store.getters.getTextMap().batch_movement }}
      </v-tab>
    </router-link>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/manufacturing/productionPlanning'">
      <v-tab>
        <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-notebook</v-icon>{{ $store.getters.getTextMap().production_planner }}
      </v-tab>
    </router-link>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/manufacturing/rawMaterials'">
      <v-tab>
        <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-beaker-outline</v-icon>{{ $store.getters.getTextMap().raw_materials }}
      </v-tab>
    </router-link>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/manufacturing/safety/incidents'">
      <v-tab>
        <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-text-box-outline</v-icon>{{ $store.getters.getTextMap().safety_incident }}
      </v-tab>
    </router-link>
      <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/manufacturing/safety/inspections'">
      <v-tab>
        <v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>mdi-police-badge-outline</v-icon>{{ $store.getters.getTextMap().safety_inspection }}
      </v-tab>
    </router-link>
    
    </v-tabs>
    <v-divider :color="$store.getters.getColorPalette().accentCode"></v-divider>
  </v-sheet>
  <router-view></router-view>
  </v-card>
                </v-col>
       
            </v-row>          -->
  </v-container>
</template>

<script>
export default {
  name: "Manufacturing",
  components: {},
  data() {
    return {
      show: "",
      tab: 0,
    };
  },

  mounted() {
    document.title = "Manufacturing";
    if (!this.$store.state.loggedIn) {
      this.$router.push("/");
    }
    // else{
    //   this.$router.push('/manufacturing/overview');
    // }
  },
};
</script>
<style scoped>
.no-padding {
  padding: 0 !important;
  margin: 0 !important;
}
.divider {
  border-top: 2px solid rgba(255, 255, 255, 0.2);
}
</style>
