var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"no-padding",attrs:{"fluid":""}},[_c('v-divider',{staticClass:"divider",attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().backgroundColorCode}}),_c('v-tabs',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"background-color":_vm.$store.getters.getColorPalette().backgroundColorCode,"grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[(
        this.$store.state.user &&
        this.$store.getters.getAccessByLevels(['logs'])
      )?_c('router-link',{style:({
        color: 'inherit',
        display: 'flex',
        'justify-content': 'space-between',
        'align-item': 'center',
        'text-decoration': 'none',
      }),attrs:{"to":'/manufacturing/checklist'}},[_c('v-tab',[_c('v-icon',{staticClass:"mr-2",attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-note-check")]),_vm._v(_vm._s(_vm.$store.getters.getTextMap().check_list)+" ")],1)],1):_vm._e(),(
        this.$store.state.user &&
        this.$store.getters.getAccessByLevels(['logs'])
      )?_c('router-link',{style:({
        color: 'inherit',
        display: 'flex',
        'justify-content': 'space-between',
        'align-item': 'center',
        'text-decoration': 'none',
      }),attrs:{"to":'/manufacturing/logbooks'}},[_c('v-tab',[_c('v-icon',{staticClass:"mr-2",attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-book-open-outline")]),_vm._v(_vm._s(_vm.$store.getters.getTextMap().log_book)+" ")],1)],1):_vm._e(),_c('router-link',{style:({
        color: 'inherit',
        display: 'flex',
        'justify-content': 'space-between',
        'align-item': 'center',
        'text-decoration': 'none',
      }),attrs:{"to":'/manufacturing/store/requests'}},[_c('v-tab',[_c('v-icon',{staticClass:"mr-2",attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-view-dashboard-outline")]),_vm._v(_vm._s(_vm.$store.getters.getTextMap().store)+" ")],1)],1),_c('router-link',{style:({
        color: 'inherit',
        display: 'flex',
        'justify-content': 'space-between',
        'align-item': 'center',
        'text-decoration': 'none',
      }),attrs:{"to":'/manufacturing/batch'}},[_c('v-tab',[_c('v-icon',{staticClass:"mr-2",attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-view-dashboard-outline")]),_vm._v(_vm._s(_vm.$store.getters.getTextMap().batch)+" ")],1)],1)],1),_c('v-divider',{staticClass:"divider",attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().backgroundColorCode}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('router-view')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }